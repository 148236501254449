.dds-h1 {
    font-size: 56px;
    font-weight: 600;
    line-height: 72px;
}

.dds-h1-xsm {
    font-size: 34px;
    font-weight: 600;
    line-height: 52px;
}

.dds-h2 {
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
}

.dds-h2-sm {
    font-size: 28px;
    font-weight: 600px;
    line-height: 24px;
}

.dds-h2-xsm {
    font-size: 26px;
    font-weight: 500px;
    line-height: 24px;
}

.dds-h3 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
}

.dds-h3-sm {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
}

.dds-h3-sm2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 28px;
}

.dds-h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
}

.dds-h4-lm {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
}

.dds-h4-sm {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
}

.dds-h5 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.dds-h5-xxl {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
}

.dds-h5-xl {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}

.dds-h5-lm {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.dds-h5-sm {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.dds-h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.dds-h6-sm {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.dds-h6-xsm {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}


.dds-body {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dds-body-semibold {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.dds-body-bold {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}

.dds-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.dds-label {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.dds-label-semibold {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
}

.dds-link-sm {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.dds-link-lsm {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.dds-icon {
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
}

.dds-icon-lm {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.dds-tag-sm {
    font-size: 10px;
    font-weight: 700;
    line-height: 20px;
}

.text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.dds-label-land {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
}

.dds-label-land-ico {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
}

.dds-highligth-text {
    font-size: 34px;
    font-weight: 600;
    line-height: 44px;
}