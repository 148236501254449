@use "sass:math";
@import 'variables';
@import './fonts.scss';
@import './global/fonts/font.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;

    .react-grid-item.react-grid-placeholder {
        background-color: transparent !important;
        z-index: -1 !important;
    }
}

:root {
    width: 100%;
    height: 100vh;
    // max-width: 1920px;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 100%;
    // font-size: betterClamp(1, 1.5);



    // light-theme configuration
    --font-color: #{$fontColor};
    --background-color: #{$backgroundColor};
    --link-color: #{$linkColor};
    --primary-color: #{$primaryColor};
    --outline-color:#{$outlineColor};
    --card-bg: #{$cardbg};
    --confirm-bg: #{$confirmbg};
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}

.main-app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

// dark theme injector
@mixin darkthemeInjector() {
    --font-color: #{$dt-fontColor};
    --background-color: #{$dt-backgroundColor};
    --link-color: #{$dt-linkColor};
    --outline-color:#{$dt-outlineColor};
    --background-light:#{$dt-backgroundColorLight};
    --card-bg: #{$dt-cardbg};
    --confirmbg: #{$dt-confirmbg};
}

[data-theme="dark"] {
    @include darkthemeInjector();
}

//scrollbar styles
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    overflow-x: auto;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
    overflow-x: auto;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #757778;
    overflow-x: auto;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.disable-feature {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.6;

    &:hover {
        background: none;
        border: none;
    }
}

// normalize css
* {
    margin: 0;
    padding: 0;

    [contenteditable=true] {
        border: 1px solid $transparent;

        &:focus {
            border: 1px solid $cool-gray-7;
            outline: none;
        }

        padding: 5px;
    }
}

.dds-required {
    &::after {
        content: "*";
        color: black;
    }
}

.dds-icon {
    font-size: 1rem !important;
}

.divider {
    &::after {
        content: "|";
        color: $cool-gray-2;
    }

    &.sm {
        font-size: 0.5rem;
    }
}

.--justify-center {
    display: flex;
    justify-content: center;
}

.--dropdown {
    min-width: 100px;
}

@function betterClamp($minSize, $maxSize, $minWidth: 720, $maxWidth: 1920) {
    // source: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
    // convert to rem
    $minSize: math.div($minSize, 1);
    $maxSize: math.div($maxSize, 1);
    $maxWidth: math.div($maxWidth, 16);
    $minWidth: math.div($minWidth, 16);
    // do calculations
    $slope: math.div(($maxSize - $minSize), ($maxWidth - $minWidth));
    $yAxisIntersection: -$minWidth * $slope +$minSize;
    // output as rem
    $minSize: $minSize * 1rem;
    $maxSize: $maxSize * 1rem;
    @return clamp(#{$minSize},
        #{$yAxisIntersection * 1rem} + #{$slope * 100vw},
        #{$maxSize});
}

//use mediaquery as required
// `sm` applies to x-small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {}

// `md` applies to small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {}

// `lg` applies to medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {}

// `xl` applies to large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// `xxl` applies to x-large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {}