@import "functions";

/* dark theme variables*/
$dt-fontColor: #eee;
$dt-backgroundColor: #111927;
$dt-backgroundColorLight: #171e2c;
$dt-linkColor: #26890d;
$dt-outlineColor: rgb(45, 55, 72);
$dt-cardbg: #121212;
$dt-confirmbg: #2424;

/*light theme variables*/
$fontColor: #333;
$backgroundColor: #eee;
$linkColor: #26890d;
$outlineColor: rgb(156, 166, 186);
$cardbg: white;
$confirmbg: gray;

/* Colors*/
$greenColor: rgb(22, 179, 100);
$blueColor: rgb(41, 112, 255);
$indigoColor: rgb(99, 102, 241);
$purpleColor: rgb(158, 119, 237);
$white: rgb(255, 255, 255);
$primaryColor: #007CB0;

//------------------------------------------------------------------------------------------------------------
// Covers
$cover_8: rgba(black, 0.08);
$cover_12: rgba(black, 0.12);
$cover_16: rgba(black, 0.16);
$cover_24: rgba(black, 0.24);
$cover_32: rgba(black, 0.32);
$cover_48: rgba(black, 0.48);
$cover_8_inverse: rgba(white, 0.08);
$cover_12_inverse: rgba(white, 0.12);
$cover_16_inverse: rgba(white, 0.16);
$cover_24_inverse: rgba(white, 0.24);
$cover_32_inverse: rgba(white, 0.32);
$cover_48_inverse: rgba(white, 0.48);

//------------------------------------------------------------------------------------------------------------
// New color palette

//transparent
$transparent: transparent;

//transparent for dark bg with white overlay
$transparent_dark_white_hover: blend-transparent($cover_16_inverse, $transparent);
$transparent_dark_white_active: blend-transparent($cover_24_inverse, $transparent);
$transparent_dark_white_s_hover: blend-transparent($cover_32_inverse, $transparent);
$transparent_dark_white_s_active: blend-transparent($cover_48_inverse, $transparent);

//transparent for dark bg with black overlay
$transparent_dark_black_hover: blend-transparent($cover_16, $transparent);
$transparent_dark_black_active: blend-transparent($cover_24, $transparent);
$transparent_dark_black_s_hover: blend-transparent($cover_32, $transparent);
$transparent_dark_black_s_active: blend-transparent($cover_48, $transparent);

//transparent for light bg with black overlay
$transparent_light_black_hover: blend-transparent($cover_8, $transparent);
$transparent_light_black_active: blend-transparent($cover_12, $transparent);
$transparent_light_black_s_hover: blend-transparent($cover_16, $transparent);
$transparent_light_black_s_active: blend-transparent($cover_24, $transparent);

//black
$black: #000000;
$black_hover: blend-transparent($cover_16_inverse, $black);
$black_active: blend-transparent($cover_24_inverse, $black);
$black_s_hover: blend-transparent($cover_32_inverse, $black);
$black_s_active: blend-transparent($cover_48_inverse, $black);

//white
$white: #FFFFFF;
$white_hover: blend-transparent($cover_8, $white);
$white_active: blend-transparent($cover_12, $white);
$white_s_hover: blend-transparent($cover_16, $white);
$white_s_active: blend-transparent($cover_24, $white);

//Accessible Green
$accessible-green: #26890D;
$accessible-green_hover: blend-transparent($cover_16, $accessible-green);
$accessible-green_active: blend-transparent($cover_24, $accessible-green);
$accessible-green_s_hover: blend-transparent($cover_32, $accessible-green);
$accessible-green_s_active: blend-transparent($cover_48, $accessible-green);
$accessible-green_s_hover_inverse: blend-transparent($cover_32_inverse, $accessible-green);
$accessible-green_s_active_inverse: blend-transparent($cover_48_inverse, $accessible-green);

//Accessible Blue
$accessible-blue: #007CB0;
$accessible-blue_hover: blend-transparent($cover_16, $accessible-blue);
$accessible-blue_active: blend-transparent($cover_24, $accessible-blue);
$accessible-blue_s_hover: blend-transparent($cover_32, $accessible-blue);
$accessible-blue_s_active: blend-transparent($cover_48, $accessible-blue);
$accessible-blue_s_hover_inverse: blend-transparent($cover_32_inverse, $accessible-blue);
$accessible-blue_s_active_inverse: blend-transparent($cover_48_inverse, $accessible-blue);

//Accessible Teal
$accessible-teal: #0D8390;
$accessible-teal_hover: blend-transparent($cover_16, $accessible-teal);
$accessible-teal_active: blend-transparent($cover_24, $accessible-teal);
$accessible-teal_s_hover: blend-transparent($cover_32, $accessible-teal);
$accessible-teal_s_active: blend-transparent($cover_48, $accessible-teal);
$accessible-teal_s_hover_inverse: blend-transparent($cover_32_inverse, $accessible-teal);
$accessible-teal_s_active_inverse: blend-transparent($cover_48_inverse, $accessible-teal);

//Red
$red: #DA291C;
$red_hover: blend-transparent($cover_16, $red);
$red_active: blend-transparent($cover_24, $red);
$red_s_hover: blend-transparent($cover_32, $red);
$red_s_active: blend-transparent($cover_48, $red);
$red_s_hover_inverse: blend-transparent($cover_32_inverse, $red);
$red_s_active_inverse: blend-transparent($cover_48_inverse, $red);

//Cool Gray 2
$cool-gray-2: #D0D0CE;
$cool-gray-2_hover: blend-transparent($cover_8, $cool-gray-2);
$cool-gray-2_active: blend-transparent($cover_12, $cool-gray-2);
$cool-gray-2_s_hover: blend-transparent($cover_16, $cool-gray-2);
$cool-gray-2_s_active: blend-transparent($cover_24, $cool-gray-2);
$cool-gray-2_s_hover_inverse: blend-transparent($cover_16_inverse, $cool-gray-2);
$cool-gray-2_s_active_inverse: blend-transparent($cover_24_inverse, $cool-gray-2);

//Cool Gray 4
$cool-gray-4: #BBBCBC;
$cool-gray-4_hover: blend-transparent($cover_8, $cool-gray-4);
$cool-gray-4_active: blend-transparent($cover_12, $cool-gray-4);
$cool-gray-4_s_hover: blend-transparent($cover_16, $cool-gray-4);
$cool-gray-4_s_active: blend-transparent($cover_24, $cool-gray-4);
$cool-gray-4_s_hover_inverse: blend-transparent($cover_16_inverse, $cool-gray-4);
$cool-gray-4_s_active_inverse: blend-transparent($cover_24_inverse, $cool-gray-4);

//Cool Gray 6
$cool-gray-6: #A7A8AA;
$cool-gray-6_hover: blend-transparent($cover_8, $cool-gray-6);
$cool-gray-6_active: blend-transparent($cover_12, $cool-gray-6);
$cool-gray-6_s_hover: blend-transparent($cover_16, $cool-gray-6);
$cool-gray-6_s_active: blend-transparent($cover_24, $cool-gray-6);
$cool-gray-6_s_hover_inverse: blend-transparent($cover_16_inverse, $cool-gray-6);
$cool-gray-6_s_active_inverse: blend-transparent($cover_24_inverse, $cool-gray-6);

//Cool Gray 7
$cool-gray-7: #97999B;
$cool-gray-7_hover: blend-transparent($cover_8, $cool-gray-7);
$cool-gray-7_active: blend-transparent($cover_12, $cool-gray-7);
$cool-gray-7_s_hover: blend-transparent($cover_16, $cool-gray-7);
$cool-gray-7_s_active: blend-transparent($cover_24, $cool-gray-7);
$cool-gray-7_s_hover_inverse: blend-transparent($cover_16_inverse, $cool-gray-7);
$cool-gray-7_s_active_inverse: blend-transparent($cover_24_inverse, $cool-gray-7);

//Cool Gray 9
$cool-gray-9: #75787B;
$cool-gray-9_hover: blend-transparent($cover_16, $cool-gray-9);
$cool-gray-9_active: blend-transparent($cover_24, $cool-gray-9);
$cool-gray-9_s_hover: blend-transparent($cover_32, $cool-gray-9);
$cool-gray-9_s_active: blend-transparent($cover_48, $cool-gray-9);
$cool-gray-9_s_hover_inverse: blend-transparent($cover_32_inverse, $cool-gray-9);
$cool-gray-9_s_active_inverse: blend-transparent($cover_48_inverse, $cool-gray-9);

//Cool Gray 10
$cool-gray-10: #63666A;
$cool-gray-10_hover: blend-transparent($cover_16, $cool-gray-10);
$cool-gray-10_active: blend-transparent($cover_24, $cool-gray-10);
$cool-gray-10_s_hover: blend-transparent($cover_32, $cool-gray-10);
$cool-gray-10_s_active: blend-transparent($cover_48, $cool-gray-10);
$cool-gray-10_s_hover_inverse: blend-transparent($cover_32_inverse, $cool-gray-10);
$cool-gray-10_s_active_inverse: blend-transparent($cover_48_inverse, $cool-gray-10);

//Cool Gray 11
$cool-gray-11: #53565A;
$cool-gray-11_hover: blend-transparent($cover_16, $cool-gray-11);
$cool-gray-11_active: blend-transparent($cover_24, $cool-gray-11);
$cool-gray-11_s_hover: blend-transparent($cover_32, $cool-gray-11);
$cool-gray-11_s_active: blend-transparent($cover_48, $cool-gray-11);
$cool-gray-11_s_hover_inverse: blend-transparent($cover_32_inverse, $cool-gray-11);
$cool-gray-11_s_active_inverse: blend-transparent($cover_48_inverse, $cool-gray-11);

//Green 1
$green-1: #E3E48D;
$green-1_hover: blend-transparent($cover_8, $green-1);
$green-1_active: blend-transparent($cover_12, $green-1);
$green-1_s_hover: blend-transparent($cover_16, $green-1);
$green-1_s_active: blend-transparent($cover_24, $green-1);
$green-1_s_hover_inverse: blend-transparent($cover_16_inverse, $green-1);
$green-1_s_active_inverse: blend-transparent($cover_24_inverse, $green-1);

//Green 2
$green-2: #C4D600;
$green-2_hover: blend-transparent($cover_8, $green-2);
$green-2_active: blend-transparent($cover_12, $green-2);
$green-2_s_hover: blend-transparent($cover_16, $green-2);
$green-2_s_active: blend-transparent($cover_24, $green-2);
$green-2_s_hover_inverse: blend-transparent($cover_16_inverse, $green-2);
$green-2_s_active_inverse: blend-transparent($cover_24_inverse, $green-2);

//Deloitte Green
$deloitte-green: #86BC25;
$deloitte-green_hover: blend-transparent($cover_8, $deloitte-green);
$deloitte-green_active: blend-transparent($cover_12, $deloitte-green);
$deloitte-green_s_hover: blend-transparent($cover_16, $deloitte-green);
$deloitte-green_s_active: blend-transparent($cover_24, $deloitte-green);
$deloitte-green_s_hover_inverse: blend-transparent($cover_16_inverse, $deloitte-green);
$deloitte-green_s_active_inverse: blend-transparent($cover_24_inverse, $deloitte-green);

//Green 4
$green-4: #43B02A;
$green-4_hover_inverse: blend-transparent($cover_16_inverse, $green-4);
$green-4_active_inverse: blend-transparent($cover_24_inverse, $green-4);
$green-4_s_hover_inverse: blend-transparent($cover_32_inverse, $green-4);
$green-4_s_active_inverse: blend-transparent($cover_48_inverse, $green-4);

//Green 5
$green-5: #009A44;
$green-5_hover_inverse: blend-transparent($cover_16_inverse, $green-5);
$green-5_active_inverse: blend-transparent($cover_24_inverse, $green-5);
$green-5_s_hover_inverse: blend-transparent($cover_32_inverse, $green-5);
$green-5_s_active_inverse: blend-transparent($cover_48_inverse, $green-5);

//Green 6
$green-6: #046A38;
$green-6_hover: blend-transparent($cover_16, $green-6);
$green-6_active: blend-transparent($cover_24, $green-6);
$green-6_s_hover: blend-transparent($cover_32, $green-6);
$green-6_s_active: blend-transparent($cover_48, $green-6);
$green-6_s_hover_inverse: blend-transparent($cover_32_inverse, $green-6);
$green-6_s_active_inverse: blend-transparent($cover_48_inverse, $green-6);

//Green 7
$green-7: #2C5234;
$green-7_hover: blend-transparent($cover_16, $green-7);
$green-7_active: blend-transparent($cover_24, $green-7);
$green-7_s_hover: blend-transparent($cover_32, $green-7);
$green-7_s_active: blend-transparent($cover_48, $green-7);
$green-7_s_hover_inverse: blend-transparent($cover_32_inverse, $green-7);
$green-7_s_active_inverse: blend-transparent($cover_48_inverse, $green-7);

//Blue 1
$blue-1: #A0DCFF;
$blue-1_hover: blend-transparent($cover_8, $blue-1);
$blue-1_active: blend-transparent($cover_12, $blue-1);
$blue-1_s_hover: blend-transparent($cover_16, $blue-1);
$blue-1_s_active: blend-transparent($cover_24, $blue-1);
$blue-1_s_hover_inverse: blend-transparent($cover_16_inverse, $blue-1);
$blue-1_s_active_inverse: blend-transparent($cover_24_inverse, $blue-1);

//Blue 2
$blue-2: #62B5E5;
$blue-2_hover: blend-transparent($cover_8, $blue-2);
$blue-2_active: blend-transparent($cover_12, $blue-2);
$blue-2_s_hover: blend-transparent($cover_16, $blue-2);
$blue-2_s_active: blend-transparent($cover_24, $blue-2);
$blue-2_s_hover_inverse: blend-transparent($cover_16_inverse, $blue-2);
$blue-2_s_active_inverse: blend-transparent($cover_24_inverse, $blue-2);

//Blue 3
$blue-3: #00A3E0;
$blue-3_hover_inverse: blend-transparent($cover_16_inverse, $blue-3);
$blue-3_active_inverse: blend-transparent($cover_24_inverse, $blue-3);
$blue-3_s_hover_inverse: blend-transparent($cover_32_inverse, $blue-3);
$blue-3_s_active_inverse: blend-transparent($cover_48_inverse, $blue-3);

//Blue 4
$blue-4: #0076A8;
$blue-4_hover: blend-transparent($cover_16, $blue-4);
$blue-4_active: blend-transparent($cover_24, $blue-4);
$blue-4_s_hover: blend-transparent($cover_32, $blue-4);
$blue-4_s_active: blend-transparent($cover_48, $blue-4);
$blue-4_s_hover_inverse: blend-transparent($cover_32_inverse, $blue-4);
$blue-4_s_active_inverse: blend-transparent($cover_48_inverse, $blue-4);

//Blue 5
$blue-5: #005587;
$blue-5_hover: blend-transparent($cover_16, $blue-5);
$blue-5_active: blend-transparent($cover_24, $blue-5);
$blue-5_s_hover: blend-transparent($cover_32, $blue-5);
$blue-5_s_active: blend-transparent($cover_48, $blue-5);
$blue-5_s_hover_inverse: blend-transparent($cover_32_inverse, $blue-5);
$blue-5_s_active_inverse: blend-transparent($cover_48_inverse, $blue-5);

//Blue 6
$blue-6: #012169;
$blue-6_hover_inverse: blend-transparent($cover_16_inverse, $blue-6);
$blue-6_active_inverse: blend-transparent($cover_24_inverse, $blue-6);
$blue-6_s_hover_inverse: blend-transparent($cover_32_inverse, $blue-6);
$blue-6_s_active_inverse: blend-transparent($cover_48_inverse, $blue-6);

//Blue 7
$blue-7: #041E42;
$blue-7_hover_inverse: blend-transparent($cover_16_inverse, $blue-7);
$blue-7_active_inverse: blend-transparent($cover_24_inverse, $blue-7);
$blue-7_s_hover_inverse: blend-transparent($cover_32_inverse, $blue-7);
$blue-7_s_active_inverse: blend-transparent($cover_48_inverse, $blue-7);

//Teal 1
$teal-1: #DDEFE8;
$teal-1_hover: blend-transparent($cover_8, $teal-1);
$teal-1_active: blend-transparent($cover_12, $teal-1);
$teal-1_s_hover: blend-transparent($cover_16, $teal-1);
$teal-1_s_active: blend-transparent($cover_24, $teal-1);
$teal-1_s_hover_inverse: blend-transparent($cover_8_inverse, $teal-1);
$teal-1_s_active_inverse: blend-transparent($cover_24_inverse, $teal-1);

//Teal 2
$teal-2: #9DD4CF;
$teal-2_hover: blend-transparent($cover_8, $teal-2);
$teal-2_active: blend-transparent($cover_12, $teal-2);
$teal-2_s_hover: blend-transparent($cover_16, $teal-2);
$teal-2_s_active: blend-transparent($cover_24, $teal-2);
$teal-2_s_hover_inverse: blend-transparent($cover_8_inverse, $teal-2);
$teal-2_s_active_inverse: blend-transparent($cover_24_inverse, $teal-2);

//Teal 3
$teal-3: #6FC2B4;
$teal-3_hover: blend-transparent($cover_8, $teal-3);
$teal-3_active: blend-transparent($cover_12, $teal-3);
$teal-3_s_hover: blend-transparent($cover_16, $teal-3);
$teal-3_s_active: blend-transparent($cover_24, $teal-3);
$teal-3_s_hover_inverse: blend-transparent($cover_8_inverse, $teal-3);
$teal-3_s_active_inverse: blend-transparent($cover_24_inverse, $teal-3);

//Teal 4
$teal-4: #00ABAB;
$teal-4_hover_inverse: blend-transparent($cover_16_inverse, $teal-4);
$teal-4_active_inverse: blend-transparent($cover_24_inverse, $teal-4);
$teal-4_s_hover_inverse: blend-transparent($cover_32_inverse, $teal-4);
$teal-4_s_active_inverse: blend-transparent($cover_48_inverse, $teal-4);

//Teal 5
$teal-5: #0097A9;
$teal-5_hover_inverse: blend-transparent($cover_16_inverse, $teal-5);
$teal-5_active_inverse: blend-transparent($cover_24_inverse, $teal-5);
$teal-5_s_hover_inverse: blend-transparent($cover_32_inverse, $teal-5);
$teal-5_s_active_inverse: blend-transparent($cover_48_inverse, $teal-5);

//Teal 6
$teal-6: #007680;
$teal-6_hover: blend-transparent($cover_16, $teal-6);
$teal-6_active: blend-transparent($cover_24, $teal-6);
$teal-6_s_hover: blend-transparent($cover_32, $teal-6);
$teal-6_s_active: blend-transparent($cover_48, $teal-6);
$teal-6_s_hover_inverse: blend-transparent($cover_32_inverse, $teal-6);
$teal-6_s_active_inverse: blend-transparent($cover_48_inverse, $teal-6);

//Teal 7
$teal-7: #004F59;
$teal-7_hover: blend-transparent($cover_16, $teal-7);
$teal-7_active: blend-transparent($cover_24, $teal-7);
$teal-7_s_hover: blend-transparent($cover_32, $teal-7);
$teal-7_s_active: blend-transparent($cover_48, $teal-7);
$teal-7_s_hover_inverse: blend-transparent($cover_32_inverse, $teal-7);
$teal-7_s_active_inverse: blend-transparent($cover_48_inverse, $teal-7);

//Orange
$orange: #ED8B00;
$orange_hover: blend-transparent($cover_8, $orange);
$orange_active: blend-transparent($cover_12, $orange);
$orange_s_hover: blend-transparent($cover_16, $orange);
$orange_s_active: blend-transparent($cover_24, $orange);
$orange_s_hover_inverse: blend-transparent($cover_8_inverse, $orange);
$orange_s_active_inverse: blend-transparent($cover_24_inverse, $orange);

//Yellow
$yellow: #FFCD00;
$yellow_hover: blend-transparent($cover_8, $yellow);
$yellow_active: blend-transparent($cover_12, $yellow);
$yellow_s_hover: blend-transparent($cover_16, $yellow);
$yellow_s_active: blend-transparent($cover_24, $yellow);
$yellow_s_hover_inverse: blend-transparent($cover_8_inverse, $yellow);
$yellow_s_active_inverse: blend-transparent($cover_24_inverse, $yellow);

//Bright Green
$bright-green: #0DF200;
$bright-green_hover: blend-transparent($cover_8, $bright-green);
$bright-green_active: blend-transparent($cover_12, $bright-green);
$bright-green_s_hover: blend-transparent($cover_16, $bright-green);
$bright-green_s_active: blend-transparent($cover_24, $bright-green);
$bright-green_s_hover_inverse: blend-transparent($cover_8_inverse, $bright-green);
$bright-green_s_active_inverse: blend-transparent($cover_24_inverse, $bright-green);

//Bright Teal
$bright-teal: #3EFAC5;
$bright-teal_hover: blend-transparent($cover_8, $bright-teal);
$bright-teal_active: blend-transparent($cover_12, $bright-teal);
$bright-teal_s_hover: blend-transparent($cover_16, $bright-teal);
$bright-teal_s_active: blend-transparent($cover_24, $bright-teal);
$bright-teal_s_hover_inverse: blend-transparent($cover_8_inverse, $bright-teal);
$bright-teal_s_active_inverse: blend-transparent($cover_24_inverse, $bright-teal);

//Bright Blue
$bright-blue: #33F0FF;
$bright-blue_hover: blend-transparent($cover_8, $bright-blue);
$bright-blue_active: blend-transparent($cover_12, $bright-blue);
$bright-blue_s_hover: blend-transparent($cover_16, $bright-blue);
$bright-blue_s_active: blend-transparent($cover_24, $bright-blue);
$bright-blue_s_hover_inverse: blend-transparent($cover_8_inverse, $bright-blue);
$bright-blue_s_active_inverse: blend-transparent($cover_24_inverse, $bright-blue);


//disabled state
$disabled-coef: 0.4;

//transparent
$transparent: transparent;


//------------------------------------------------------------------------------------------------------------
// THEMES
// green
$theme-green_main: $accessible-green;
$theme-green_hover: $accessible-green_hover;
$theme-green_active: $accessible-green_active;
$theme-green_s_hover: $accessible-green_s_hover;
$theme-green_s_active: $accessible-green_s_active;
$theme-green_s_hover_inverse: $accessible-green_s_hover_inverse;
$theme-green_s_active_inverse: $accessible-green_s_active_inverse;

// dark
$theme-dark_main: $black;
$theme-dark_hover: $black_hover;
$theme-dark_active: $black_active;
$theme-dark_s_hover: $black_s_hover;
$theme-dark_s_active: $black_s_active;

// white
$theme-white_main: $white;
$theme-white_hover: $white_hover;
$theme-white_active: $white_active;

// blue
$theme-blue_main: $accessible-blue;
$theme-blue_hover: $accessible-blue_hover;
$theme-blue_active: $accessible-blue_active;
$theme-blue_s_hover: $accessible-blue_s_hover;
$theme-blue_s_active: $accessible-blue_s_active;
$theme-blue_s_hover_inverse: $accessible-blue_s_hover_inverse;
$theme-blue_s_active_inverse: $accessible-blue_s_active_inverse;

// danger
$theme-danger_main: $red;
$theme-danger_hover: $red_hover;
$theme-danger_active: $red_active;
$theme-danger_s_hover: $red_s_hover;
$theme-danger_s_active: $red_s_active;
$theme-danger_s_hover_inverse: $red_s_hover_inverse;
$theme-danger_s_active_inverse: $red_s_active_inverse;

//------------------------------------------------------------------------------------------------------------
//Focus state
$focus: 2px solid $blue-5;

//----------------------------------------------------------------------------------------------------
// Transition duration
$transition-duration: .15s;

//----------------------------------------------------------------------------------------------------
//FONTS
//regular
$regular-11: getFontStyle(11px, 16px, 400);
$regular-12: getFontStyle(12px, 16px, 400);
$regular-12-larger: getFontStyle(12px, 20px, 400);
$regular-13: getFontStyle(13px, 16px, 400);
$regular-14: getFontStyle(14px, 16px, 400);
$regular-14-larger: getFontStyle(14px, 20px, 400);
$regular-14-enlarged: getFontStyle(14px, 22px, 400);
$regular-14-extraLarge: getFontStyle(14px, 24px, 400);
$regular-15: getFontStyle(15px, 20px, 400);
$regular-16: getFontStyle(16px, 20px, 400);
$regular-16-enlarged: getFontStyle(16px, 24px, 400);
$regular-17: getFontStyle(17px, 20px, 400);

//semibold
$semibold-10: getFontStyle(10px, 16px, 600);
$semibold-12: getFontStyle(12px, 16px, 600);
$semibold-14: getFontStyle(14px, 16px, 600);
$semibold-14-enlarged: getFontStyle(14px, 20px, 600);
$semibold-14-larger: getFontStyle(14px, 24px, 600);
$semibold-15: getFontStyle(15px, 20px, 600);
$semibold-16: getFontStyle(16px, 20px, 600);
$semibold-16-enlarged: getFontStyle(16px, 24px, 600);
$semibold-18: getFontStyle(18px, 24px, 600);

// bold
$bold-14: getFontStyle(14px, 20px, 700);

//----------------------------------------------------------------------------------------------------
//ICON
$icon--font-size: 16px;
$icon--line-height: 16px;

//ICON LARGE
$icon-lg--font-size: 20px;
$icon-lg--line-height: 20px;